/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, live chat bez obmedzenia',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  extCode: 'SK',
  gtm: 'GTM-KZTMRF2',
  gtmCountry: 'GTM-K68FGNG',
  completeProfileInputs: ['phoneNumber'],
  id: 'ck5zg6eqttdmb0b62ry80kgh4',
  dvp4m: false,
  gsv: null,

  appLink: null,
  didomi: 'ATQzqkzE',
};
